// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
//@import "node_modules/font-awesome/scss/font-awesome.scss";
//@import '~@fortawesome/fontawesome-free/scss/fontawesome';
//@import '~@fortawesome/fontawesome-free/scss/regular';
//@import '~@fortawesome/fontawesome-free/scss/solid';
//@import '~@fortawesome/fontawesome-free/scss/brands';


// Variables
//@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';


//jstree
//@import '~jstree/src/themes/default/style.css';

//fontawesome
/*@import '../../resources/fontawesome/scss/fontawesome';
@import '../../resources/fontawesome/scss/regular';
@import '../../resources/fontawesome/scss/solid';
@import '../../resources/fontawesome/scss/brands';
@import '../../resources/fontawesome/scss/light';
@import '../../resources/fontawesome/scss/duotone';*/


//highcharts
//@import "~highcharts/css/highcharts";


// Datatables
@import '~datatables.net-bs4/css/dataTables.bootstrap4.css';
@import '~datatables.net-responsive-bs4/css/responsive.bootstrap4.css';
@import '~datatables.net-buttons-bs4/css/buttons.bootstrap4.css';
@import "~datatables.net-select-bs4/css/select.bootstrap4.css";
@import "~datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.css";
@import "~datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.css";

//Select2
@import "~select2/dist/css/select2.css";


//jquery-ui
@import '~jquery-ui/themes/base/all.css';

//tablas
//@import '~datatables/media/css/jquery.dataTables.css';

//sweetalert2
@import "~sweetalert2/src/sweetalert2";

//sb-admin
@import "sb-admin/sb-admin-2";

// material
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');
@import url('https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css');

